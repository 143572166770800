import "../index.css"

const Footer = () => {
    return (
        <footer className="footer--pin">
            <a href="https://api.whatsapp.com/send?phone=17786683667">
            <p>CONTACT US  </p>
            <img src="whatsapplogo.png" alt="logo1" className="logo1"/>
            </a>
        </footer>
    )
}

export default Footer