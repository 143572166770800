import './services.css';
import ServicePlaceholder from './ServicePlaceholder';
import {Circles} from "react-loading-icons";


const Services = (props) =>{
    const services = props.services;
    const isPending = props.isPending;

    return (
        <div className="services">
        {isPending && <div className='loader spin'><Circles fill='#000000'/></div>}
        {services && services.map((service)=>(
            <ServicePlaceholder service={service} key={service.id}/>
        ))}      
        </div>
    )
}

export default Services;