import './services.css';
import {Link} from 'react-router-dom';

const ServicePlaceholder = (props) => {
    const serviceName = props.service.serviceName;
    const image = props.service.backgroundImage;
    console.log();

    if (props.service.subServices.length > 0){
        return (
            <Link to={serviceName.toLowerCase()} style={{ textDecoration: 'none' }}>
            <div className="service" style={{backgroundImage: `url(${image})`}}>
                <div className="text">
                    <p>{serviceName}</p>
                </div>
            </div>
            </Link>
        );
    }
    }
    

export default ServicePlaceholder;