import '../index.css';
import { Link } from 'react-router-dom';


const Header = () => {
    return (
        <header>
            <h1><Link to="/" style={{ textDecoration: 'none' }}>GMC</Link></h1>
            <h2 className="header-subtitle">Your Gateway to Premier Service Providers for All Business Needs </h2>
            <p className="header-description">Join a network of excellence and discover services tailored to your unique needs. From creative design and strategic marketing to robust logistics and advanced software solutions, find your future partners with us.
            </p>
        </header>
    );
}

export default Header;