import Services from './components/Services';
import { BrowserRouter as Router } from 'react-router-dom';
import { Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import useFetch from './useFetch';
import Service from './components/Service'
import Footer from './components/Footer';
import services from './services.json'



function App() {

  //const {services, isPending} = useFetch(`https://gmc-react.onrender.com/services`);

  const isPending = false;
  return (
    <div className="App">
      <Router>
        <div className='content-container'>
          <Header />
          <Routes>
            <Route exact path='/' element={<Services services={services} isPending={isPending} />} />
            {services && services.map((service) => (
              <Route path={service.serviceName.toLowerCase()} key={service.id} element={<Service service={service} key={service.id} />} />
            ))}
          </Routes>
        </div>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
