import { useState } from 'react';
import { useEffect } from 'react';
import './services.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom';

const Service = (props) => {
    const subs = props.service.subServices;

    const [rotations, setRotations] = useState(subs.reduce((acc, sub) => {
        acc[sub.name] = 0;
        return acc;
    }, {}));

    const handleClick = (subName) => {
        setRotations(prevRotations => ({
            ...prevRotations,
            [subName]: prevRotations[subName] === 90 ? 0 : 90
        }));
    };

    const [loaded, setLoaded] = useState(false);
    
    useEffect(() => {
        setLoaded(true);
    }, []);

    return (
        <div className='sub-wrapper'>
            {subs.map(sub =>(
                sub.contacts.length>0 && (
                <div className="sub" key={sub.name} style={{marginBottom: rotations[sub.name] === 90 && sub.contacts.length!==0 ? `${(sub.contacts.length+1)*68}px` : '50px' }}>
                    <div className='sub-name'onClick={() => {handleClick(sub.name)}}>
                    <p ><FontAwesomeIcon icon={faChevronRight}   style={{ transform: `rotate(${rotations[sub.name]}deg)`,  transition : "all 0.5sec ease", marginRight : "5px"}}/>
                    {sub.name}</p>
                    </div>
                    {rotations[sub.name] === 90 && sub.contacts.length > 0 && (
                        <div className='sub-contcts flip-in-hor-bottom' >
                            <table>
                            <thead>
                                <tr>
                                <th>NAME</th>
                                <th>WEBSITE</th>
                                <th>PHONE</th>
                                <th>ADDRESS</th>
                                </tr>
                            </thead>
                            <tbody>
                            {sub.contacts.map( (person, id) => (
                                <tr key={id} style={{height: "30px"}}>
                                    <td>{person.name}</td><td><Link to={person.website.includes("@") ? ("mailto:" + person.website) : ("http://" + person.website)}>{person.website}</Link></td>
                                    
                                    <td><Link to={"tel:"+ person.phone} style={{color : "black"}}><b>{person.phone}</b></Link></td>
                                    <td>{person.address}</td>
                                </tr>
                            ))}
                            </tbody>
                            </table>
                            </div>
                    )}
                </div>
                )
            ))
            }
            {!loaded && (
                <div className='empty'>
                    <p>Loading...</p>
                </div>
            )}
            {loaded && document.querySelector('.sub') === null && (
                <div className='empty'>
                    <p>Unfortunately, we don't have any partners in this field yet. If you want your business to be here, please contact us via WhatsApp.</p>
                </div>
            )}     
        </div>
    )
}

export default Service;